import { SmallCloseIcon } from "@chakra-ui/icons";
import { Tab, Text, useTab, UseTabProps } from "@chakra-ui/react"
import { ReactElement } from "react";

interface FormTabProps extends UseTabProps {
  icon?: ReactElement
  text: string
  btnClose?: boolean
  chave: string
  removeForm: (chave: string) => void
}

export function FormTab({ text, icon, removeForm, chave, btnClose = true, ...others }: FormTabProps) {
  // const styles = useStyles();
  const tabProps = useTab(others);
  const isSelected = !!tabProps["aria-selected"];

  return (
      <Tab mt="1" fontSize="14px" fontWeight="bold" {...tabProps}
          _selected={{ color: 'gray.900', bg: 'white', borderTopRadius: "8px" }}
          _active={{ color: 'gray.900', bg: 'white', borderTopRadius: "8px" }}
      >
          {icon}
          <Text ml="1">{text}</Text>
          {tabProps.children}
          {!!(btnClose && isSelected) && <SmallCloseIcon color="red.500" mt="-5" mr="-3" onClick={()=>removeForm(chave)} />}
      </Tab>
  );
}