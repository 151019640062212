import { FormControl, FormErrorMessage, FormLabel, InputGroup, InputLeftAddon, InputRightAddon, InputRightElement, Select, SelectProps } from "@chakra-ui/react";
import { ReactElement } from "react";
import { FieldError } from "react-hook-form";
import { forwardRef, ForwardRefRenderFunction } from "react";

export interface CampoSelectOption {
  valor: string|number, 
  texto: string|number
}

export interface CampoSelectProps extends SelectProps {
  name: string
  label?: string
  leftIcon?: ReactElement //Ícone cinza
  leftElement?: ReactElement //Ícone dentro do campo
  rightIcon?: ReactElement
  rightElement?: ReactElement
  type?: string
  size?: string
  error?: FieldError
  dados?: CampoSelectOption[]
  todos?: boolean
  selecione?: boolean
}

const CampoSelectBase: ForwardRefRenderFunction<HTMLSelectElement, CampoSelectProps> = 
    ({ name, label, leftIcon, leftElement, rightIcon, rightElement, 
        size = "sm", error = null, dados = null, todos = false, selecione = false, ...others }, ref) => {
    return (
        <FormControl isInvalid={!!error}>
            {!!label && <FormLabel fontSize="14px" fontWeight="700" htmlFor={name}>{label}</FormLabel>}
            <InputGroup size={size}>
                {!!leftIcon && <InputLeftAddon children={leftIcon} />}
                {!!leftElement && <InputLeftAddon children={leftElement} />}
                <Select 
                  size={size}
                  name={name}
                  id={name}
                  focusBorderColor="#007bff"
                  borderColor="#ced4da"
                  _hover={{
                      borderColor: "#ced4da",
                  }}
                  ref={ref}
                  {...others}
                >
                  {selecione && <option value=''>Selecione...</option>}
                  {todos && <option value=''>Todos...</option>}
                  {
                    dados?.map(i => {
                      return <option key={i.valor} value={i.valor}>{i.texto}</option>
                    })
                  }
                </Select>
                {!!rightIcon && <InputRightAddon bg="gray.300" children={rightIcon} />}
                {!!rightElement && <InputRightElement children={rightElement} />}
            </InputGroup>
            {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
            {/* {((!errorMessage) &&(helper)) && <FormHelperText>{helper}</FormHelperText>} */}
        </FormControl>
    )
}

export const CampoSelect = forwardRef(CampoSelectBase)