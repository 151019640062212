import { Button, Flex, Image, Stack } from "@chakra-ui/react";
import { useSistema, Usuario } from "../../hooks/useSistema";
import logo from "../../imgs/supportazul.png"
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { CampoTexto } from "../../components/campos/CampoTexto";
import { FaLock, FaPencilAlt } from "react-icons/fa";
import { CampoSenha } from "../../components/campos/CampoSenha";

const loginSchema = yup.object().shape({
    usuario: yup.string().required('Campo obrigatório'),
    senha: yup.string().required('Campo obrigatório')
})

export function FormLogin() {
    const { login } = useSistema()

    const { 
        register, 
        handleSubmit, 
        formState
    } = useForm<Usuario>(
        {
            resolver: yupResolver(loginSchema), 
            defaultValues: {
                usuario: JSON.parse(localStorage.getItem('_projeto-support_ultimo-user') ?? '""'),
            }
        }
    )
    const errors = formState.errors

    const handleSignIn: SubmitHandler<Usuario> = async (values) => {
        await login(values)
    }

    return (
        <Flex
            w="100vw"
            h="100vh"
            alignItems="center"
            justifyContent="center"
            flexDir="column"
            bg="#E9ECEF"
        >
            <Image src={logo} alt='Logo' height="100" mb="14px" />
            <Flex
                as="form"
                width="100%"
                maxWidth={360}
                bg="white"
                p="20px"
                boxShadow="0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2)"
                flexDir="column"
                onSubmit={handleSubmit(handleSignIn)} 
            >
                <Stack mt="20px" spacing="4">
                    <CampoTexto
                        placeholder="Usuário"
                        rightElement={<FaPencilAlt />}
                        size="md"
                        error={errors.usuario}
                        {...register('usuario')} />
                    <CampoSenha
                        placeholder="Senha"
                        rightElement={<FaLock />}
                        size="md"
                        error={errors.senha}
                        {...register('senha')} />
                    <Flex justifyContent="right">
                        <Button 
                            width="95px" colorScheme="blue"
                            type="submit" isLoading={formState.isSubmitting}
                        >Entrar</Button>
                    </Flex>
                </Stack>
            </Flex>
        </Flex>
    )
}