import { Avatar, Button, Flex, HStack, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Text } from "@chakra-ui/react";
import { useSistema } from "../../hooks/useSistema";
import { getIcone } from "./icones";

export function TagUser() {
    const { logout, usuario, modalEmpresa } = useSistema()
    return (
        <Flex bgColor="primary" align="flex-end" ml="auto" px="4" py="6px">
            <Popover >
                {({ isOpen }) => (
                    <>
                        <PopoverTrigger>
                            <Flex flexDir="row">
                                <Avatar
                                    name={usuario.usuario}
                                    src={`https://www.gravatar.com/avatar/${usuario.md5}?d=lkwehqr&s=512`}
                                    size="sm"
                                    mr="6px"
                                    mt="-1"
                                    bg="gray"
                                />
                                <Text color="white" as="button" fontSize="sm">{usuario.email}</Text>
                            </Flex>
                        </PopoverTrigger>
                        <PopoverContent borderColor="blackAlpha.100" width="250px">
                            <PopoverArrow />
                            <PopoverHeader
                                bgColor="primary"
                                color="white"
                            >
                                <Flex
                                    flexDir="column" alignItems="center">
                                    <Avatar
                                        name={usuario.usuario}
                                        src={`https://www.gravatar.com/avatar/${usuario.md5}?d=lkwehqr&s=512`}
                                        size="xl"
                                    />
                                    <Text
                                        mt="10px"
                                        fontSize="md"
                                        fontWeight="semibold"
                                    >{usuario.usuario}</Text>
                                    <Text
                                        mt="2px"
                                        fontSize="sm"
                                    >{usuario.email}</Text>
                                </Flex>
                            </PopoverHeader>
                            <PopoverBody color="gray.900">
                                <Flex flexDir="column" alignItems="center" cursor="pointer" onClick={modalEmpresa.onOpen}>
                                    <HStack>
                                        <Text fontSize="small" align="center">
                                            {usuario.empresa?.NomEmp}
                                        </Text>
                                        {getIcone('pen', {})}
                                    </HStack>
                                </Flex>
                            </PopoverBody>
                            <PopoverFooter>
                                <Flex flexDir="column" alignItems="flex-end">
                                    <Button
                                        size="sm"
                                        variant="outline"
                                        colorScheme="blackAlpha"
                                        leftIcon={getIcone('sign-out-alt', {})}
                                        onClick={logout}
                                    >
                                        Sair
                                    </Button>
                                </Flex>
                            </PopoverFooter>
                        </PopoverContent>
                    </>
                )}
            </Popover>
        </Flex>
    )
}