import { Accordion, AccordionItem,  Avatar, Box, Divider,  HStack, Stack, Text } from "@chakra-ui/react";
import { FormProps, useFormSistema } from "../../hooks/useFormSistema";
import { useSistema } from "../../hooks/useSistema";
import { getIcone } from "./icones";

const formRelatorioContasReceber: FormProps = {
    chave: 'relatorio-analitico-contas-receber',
    icone: 'chart-bar',
    nome: 'Relatório Analítico de Contas a Receber',
    titulo: 'Relatório Analítico de Contas a Receber',
    botoes: [
    ],
    fechar: true,
}

const formRelatorioContasPagar: FormProps = {
    chave: 'relatorio-analitico-contas-pagar',
    icone: 'chart-bar',
    nome: 'Relatório Analítico de Contas a Pagar',
    titulo: 'Relatório Analítico de Contas a Pagar',
    botoes: [
    ],
    fechar: true,
}

const formRateio: FormProps = {
    chave: 'relatorio-rateio',
    icone: 'pie-chart',
    nome: 'Relatório de Rateios',
    titulo: 'Relatório de Rateios',
    botoes: [
    ],
    fechar: true,
}

export function Sidebar() {
    const { sidebar, usuario, modalEmpresa } = useSistema()
    const { addForm } = useFormSistema()

    return (
        <Box bgColor="menuBackground" as="aside" 
        w={(sidebar.isOpen ? "250px" : "4.6rem")} 
        minWidth={(sidebar.isOpen ? "250px" : "4.6rem")} 
        transition="0.4s"
        pr="1px">
            <Stack spacing="3" align="flex-start" width="100%">
                <Box px="2" pt="3" width="100%">
                    <HStack width="100%" cursor="pointer" onClick={modalEmpresa.onOpen}>
                        <Avatar
                            size="sm"
                            bg="white"
                            textColor="menuBackground"
                            name={usuario.empresa?.NomEmp}
                        />
                        {sidebar.isOpen &&
                            <Text
                                color="menuFont"
                                fontSize="small"
                            >{usuario.empresa?.NomEmp}</Text>}
                    </HStack>
                </Box>
                <Divider />
                <Stack width="100%">
                    <Accordion allowMultiple width="100%">
                        <AccordionItem border="0">
                            <HStack
                                spacing="0"
                                as="a"
                                cursor="pointer"
                                title="Relatório Analítico de Contas a Receber"
                                _hover={{ color: "white" }}
                                bg="menuBackground"
                                color="menuFont"
                                py="6.4px"
                                pl="4"
                                onClick={()=> { addForm(formRelatorioContasReceber) }}
                            >
                                <Box mr="2">
                                    {getIcone('chart-bar', { size: '18px' })}
                                </Box>
                                <Text fontSize="14px" flex='1' textAlign='left'>
                                    {(sidebar.isOpen ? "Relatório Analítico de Contas a Receber" : <>&nbsp;</>)}
                                </Text>
                            </HStack>
                            <HStack
                                spacing="0"
                                as="a"
                                cursor="pointer"
                                title="Relatório Analítico de Contas a Pagar"
                                _hover={{ color: "white" }}
                                bg="menuBackground"
                                color="menuFont"
                                py="6.4px"
                                pl="4"
                                onClick={()=> { addForm(formRelatorioContasPagar) }}
                            >
                                <Box mr="2">
                                    {getIcone('chart-bar', { size: '18px' })}
                                </Box>
                                <Text fontSize="14px" flex='1' textAlign='left'>
                                    {(sidebar.isOpen ? "Relatório Analítico de Contas a Pagar" : <>&nbsp;</>)}
                                </Text>
                            </HStack>
                            <HStack
                                spacing="0"
                                as="a"
                                cursor="pointer"
                                title="Relatório Rateios"
                                _hover={{ color: "white", backgroundColor: 'menuBackgroundSelected' }}
                                color="menuFont"
                                bg="menuBackground"
                                py="6.4px"
                                pl="4"
                                onClick={()=> { addForm(formRateio) }}
                            >
                                <Box mr="2">
                                    {getIcone('chart-pie', { size: '18px' })}
                                </Box>
                                <Text fontSize="14px" flex='1' textAlign='left'>
                                    {(sidebar.isOpen ? "Relatório Rateios" : <>&nbsp;</>)}
                                </Text>
                            </HStack>
                        </AccordionItem>
                    </Accordion>
                </Stack>
            </Stack>
        </Box>
    )
}