import { Center, Modal, ModalContent, ModalOverlay, Spinner, useDisclosure, UseDisclosureProps, useToast } from '@chakra-ui/react';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { api } from '../services/api';

export interface Empresa {
    id?: number;
    NomEmp?: string;
}

export interface Usuario {
  id?: number;
  usuario?: string;
  senha?: string;
  email?: string;
  md5?: string;
  level?: number;
  expires?: Date;
  empresa?: Empresa
  empresas?: Empresa[]
}

type UsuarioLogin = Omit<Usuario, 'id' | 'email'>;

interface SistemaContextData {
    usuario: Usuario,
    sidebar: UseDisclosureProps,
    modalEmpresa: UseDisclosureProps;
    login: (usuario: UsuarioLogin) => Promise<void>;
    logout: () => Promise<void>;
    showLoader: () => void;
    hideLoader: () => void;
    trocarEmpresa: (empresa: string) => void;
}

interface SistemaProviderProps {
    children: ReactNode
}

const SistemaContext = createContext<SistemaContextData>(
    {} as SistemaContextData
);

export function SistemaProvider({children}: SistemaProviderProps) {
    const userKey = '_projeto-support_user'
    const ultimoUserKey = '_projeto-support_ultimo-user'

    const [usuario, setUsuario] = useState<Usuario>({})
    const sidebar = useDisclosure({defaultIsOpen: true})
    const loader = useDisclosure({defaultIsOpen: false})
    const modalEmpresa = useDisclosure({defaultIsOpen: false})
    const toast = useToast()

    useEffect(() => {
        const user = localStorage.getItem(userKey)

        if(user){
            setUsuario(JSON.parse(user))
        }
    }, []);

    async function login(login: UsuarioLogin) {
        showLoader()
        try {
            const response = await api.post('auth/login', {
                'Usuario': login.usuario,
                'password': login.senha
            })

            let expires = new Date()
            expires.setHours(expires.getHours() + 4)

            const user: Usuario = {
                id: response.data.obj.usuario.id,
                usuario: response.data.obj.usuario.Usuario,
                email: response.data.obj.usuario.email,
                level: response.data.obj.usuario.level,
                md5: response.data.obj.usuario.emailMD5,
                empresa: response.data.obj?.empresa,
                empresas: response.data.obj?.todasEmpresas,
                expires: expires
            }

            localStorage.setItem(userKey, JSON.stringify(user))
            //Registra último login
            localStorage.setItem(ultimoUserKey, JSON.stringify(user.usuario))
            
            setUsuario(user)

            hideLoader()
        } catch (error: any) {
            toast({
                title: "Erro",
                position: "top-right",
                status: "error",
                description: error.response?.data?.message ?? 'Falha ao realizar login',
                isClosable: true,
                duration: 1500,
            })
            hideLoader()
        }
    }

    async function logout() {
        showLoader()

        setTimeout(() => {
            localStorage.removeItem(userKey)
            setUsuario({
            })

            hideLoader()
        }, 1000);
    }
    
    async function trocarEmpresa(empresa: string) {
        showLoader()

        setTimeout(() => {
            const u: Usuario = {
                ...usuario, 
                empresa: usuario.empresas?.find(e => (e.id + '') === empresa)
            }

            setUsuario(u)
            localStorage.setItem(userKey, JSON.stringify(u))

            hideLoader()
        }, 1000);
    }
    
    function showLoader() {
        loader.onOpen()
    }
    
    function hideLoader() {
        loader.onClose()
    }

    return (
        <SistemaContext.Provider value={{ 
                usuario, 
                sidebar, 
                login, 
                logout, 
                showLoader, 
                hideLoader, 
                modalEmpresa, 
                trocarEmpresa
            }}>
            <Modal closeOnOverlayClick={false} isOpen={loader.isOpen} onClose={loader.onClose}>
                <ModalOverlay />
                <ModalContent bg="transparent" w="400">
                    <Center>
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='primary'
                            size='xl' 
                            alignSelf="center"
                        />
                    </Center>
                </ModalContent>
            </Modal>
            {/* <ModalTrocarEmpresa disclosure={dclEmpresa} /> */}
            {children}
        </SistemaContext.Provider>
    )
}

export function useSistema() {
    const context = useContext(SistemaContext)

    return context;
}