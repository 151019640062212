// import { Grid, GridItem } from "@chakra-ui/react"
// import { FaPencilAlt } from "react-icons/fa"
// import { CampoTexto } from "../../components/campos/CampoTexto"

export function Dashboard(){
  
  return (
    <>
      {/* <Grid
          templateColumns='repeat(12, 1fr)'
          gap="1px"
      >
          <GridItem w='100%' bg='blue.500' px="7.5px">1</GridItem>
          <GridItem w='100%' bg='blue.500' px="7.5px">2</GridItem>
          <GridItem w='100%' bg='blue.500' px="7.5px">3</GridItem>
          <GridItem w='100%' bg='blue.500' px="7.5px">4</GridItem>
          <GridItem w='100%' bg='blue.500' px="7.5px">5</GridItem>
          <GridItem w='100%' bg='blue.500' px="7.5px">6</GridItem>
          <GridItem w='100%' bg='blue.500' px="7.5px">7</GridItem>
          <GridItem w='100%' bg='blue.500' px="7.5px">8</GridItem>
          <GridItem w='100%' bg='blue.500' px="7.5px">9</GridItem>
          <GridItem w='100%' bg='blue.500' px="7.5px">10</GridItem>
          <GridItem w='100%' bg='blue.500' px="7.5px">11</GridItem>
          <GridItem w='100%' bg='blue.500' px="7.5px">12</GridItem>
      </Grid>
      <Grid
          templateColumns='repeat(12, 1fr)'
          gap="1px"
      >
          <GridItem bg="white" w='100%' px="7.5px" colSpan={3}>
              <CampoTexto
                  name="teste"
                  label="Teste"
                  rightIcon={<FaPencilAlt />}
              />
          </GridItem>
          <GridItem w='100%' bg='blue.500' px="7.5px" colSpan={3}>2</GridItem>
          <GridItem w='100%' bg='blue.500' px="7.5px" colSpan={3}>3</GridItem>
          <GridItem w='100%' bg='blue.500' px="7.5px" colSpan={3}>4</GridItem>
      </Grid> */}
    </>
  )
}