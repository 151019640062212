import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftAddon, InputProps, InputRightAddon, InputRightElement } from "@chakra-ui/react";
import { ReactElement } from "react";
import { FieldError } from "react-hook-form";
import { forwardRef, ForwardRefRenderFunction } from "react";

export interface CampoTextoGenericoProps extends InputProps {
    name: string
    label?: string
    leftIcon?: ReactElement //Ícone cinza
    leftElement?: ReactElement //Ícone dentro do campo
    rightIcon?: ReactElement
    rightElement?: ReactElement
    type?: string
    size?: string
    error?: FieldError
}

const CampoTextoGenericoBase: ForwardRefRenderFunction<HTMLInputElement, CampoTextoGenericoProps> = 
    ({ name, label, leftIcon, leftElement, rightIcon, rightElement, size = "sm", error = null, ...others }, ref) => {
    return (
        <FormControl isInvalid={!!error}>
            {!!label && <FormLabel fontSize="14px" fontWeight="700" htmlFor={name}>{label}</FormLabel>}
            <InputGroup size={size}>
                {!!leftIcon && <InputLeftAddon children={leftIcon} />}
                {!!leftElement && <InputLeftAddon children={leftElement} />}
                <Input size={size}
                    name={name}
                    id={name}
                    focusBorderColor="#007bff"
                    borderColor="#ced4da"
                    _hover={{
                        borderColor: "#ced4da",
                    }}
                    ref={ref}
                    {...others}
                />
                {!!rightIcon && <InputRightAddon bg="gray.300" children={rightIcon} />}
                {!!rightElement && <InputRightElement children={rightElement} />}
            </InputGroup>
            {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
            {/* {((!errorMessage) &&(helper)) && <FormHelperText>{helper}</FormHelperText>} */}
        </FormControl>
    )
}

export const CampoTextoGenerico = forwardRef(CampoTextoGenericoBase)