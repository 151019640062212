import { Box } from "@chakra-ui/react";
import { getIcone } from "../../components/layout/icones";

export interface BotaoTituloProps {
  id: string;
  icone: string;
  cor?: string;
  title?: string;
  submit?: boolean;
  onClick?: (props?: any) => void;
  onClickProps?: any;
}

export function BotaoTitulo({ icone, cor, title, submit=false, onClick, onClickProps }: BotaoTituloProps) {
  return (
    <Box 
      type={(!submit ? "button" : "submit")}
      title={title} 
      as="button" ml="1" color={cor}
      transitionDuration="normal" transitionProperty="common"
      _active={{ bg: 'gray.200' }}
      _disabled={{ cursor: 'not-allowed', opacity: 0.4 }}
      _selected={{ borderColor: "currentColor" }}
      onClick={() => { if(onClick){ onClick(onClickProps)}}}
    >
      {getIcone(icone, { size:"29px" })}
    </Box>
  );
}