import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, UseDisclosureProps } from "@chakra-ui/react"
import { SubmitHandler, useForm } from "react-hook-form";
import { useFormSistema } from "../../hooks/useFormSistema";
import { useSistema } from "../../hooks/useSistema";
import { CampoSelect } from "../campos/CampoSelect";
import { getIcone } from "./icones";

interface ModalTrocarEmpresaProps {
  disclosure: UseDisclosureProps
}

interface FormTrocarEmpresa {
  empresa: string
}

export function ModalTrocarEmpresa({disclosure}: ModalTrocarEmpresaProps){
  const empty = () => {} //gambiarra
  const { usuario, trocarEmpresa } = useSistema()
  const { resetForms } = useFormSistema()
  const idempresa = (usuario.empresa?.id + '')

  const { 
      register, 
      handleSubmit
  } = useForm<FormTrocarEmpresa>({
      defaultValues: {
          empresa: idempresa
      }
  })

  const handleTroca: SubmitHandler<FormTrocarEmpresa> = async (values) => {
    try {
      trocarEmpresa(values.empresa)
      resetForms()

      if(disclosure.onClose)
        disclosure.onClose()

    } catch (error) {
    }
  }

  return (
    <Modal isOpen={disclosure.isOpen ?? false} onClose={disclosure.onClose ?? empty} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleTroca)}>
          <ModalHeader 
              bg="primary" color="white" 
              fontWeight="semibold" p="2"
          >Trocar Empresa</ModalHeader>
          <ModalCloseButton color="white" fontWeight="semibold" />
          <ModalBody>
            <CampoSelect
              dados={usuario.empresas?.map(e => ({valor: (e.id + ''), texto: e.NomEmp ?? '' }))}
              {...register('empresa')} />
          </ModalBody>
          <ModalFooter py="2">
              <Button
                  size="sm"
                  colorScheme="blue"
                  leftIcon={getIcone('sync', {})}
                  type="submit"
              >
                  Trocar
              </Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
  )
}