import { Home } from "./modules/home/Home";
import { FormLogin } from "./modules/login/FormLogin";
import { useSistema } from "./hooks/useSistema"

export function App() {
  const { usuario } = useSistema()

  if (usuario?.id) {
    return <Home />
  } else {
    return <FormLogin />
  }
}