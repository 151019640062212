import { forwardRef, ForwardRefRenderFunction } from "react";
import { CampoTextoGenerico, CampoTextoGenericoProps } from "./CampoTextoGenerico";

const CampoSenhaBase: ForwardRefRenderFunction<HTMLInputElement, CampoTextoGenericoProps> = 
    ({ ...others }, ref) => {
    return (
        <CampoTextoGenerico type="password" ref={ref} {...others} />
    )
}

export const CampoSenha = forwardRef(CampoSenhaBase)