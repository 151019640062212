import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Container, Grid, GridItem, Tab, Table, TableContainer, TabList, TabPanel, TabPanels, Tabs, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CampoData } from "../../components/campos/CampoData";
import { useFormSistema } from "../../hooks/useFormSistema"
import { useSistema } from "../../hooks/useSistema";
import { api } from "../../services/api";

interface FormRelatorioRateioData {
    dataInicio?: string;
    dataFim?: string;
}

interface RateioItem {
  CodCnc?: string;
  CncDsc?: string;
  CfiNiv?: string;
  CfiDsc?: string;
  CctNiv?: string;
  CctDsc?: string;
  DatMvt?: string;
  DatVen?: string;
  DatEmi?: string;
  DscMvt?: string;
  NumItm?: string;
  OrgItm?: string;
  OrgTit?: string;
  VlrItm?: string;
  UsuGer?: string;
}

export function FormRelatorioRateio(){
  const [pagar, setPagar] = useState<RateioItem[]>([])
  const [receber, setReceber] = useState<RateioItem[]>([])
  const [caixa, setCaixa] = useState<RateioItem[]>([])
  const [entradas, setEntradas] = useState<RateioItem[]>([])
  const { showLoader, hideLoader, usuario } = useSistema()

  //Define datas
  let agora = new Date()
  let dataInicio = new Date(2022, 0, 1, 0, 0, 0)
  dataInicio.setDate(1)
  dataInicio.setFullYear(agora.getFullYear())
  dataInicio.setMonth(agora.getMonth() - 1)

  let dataFim = new Date(2022, 0, 1, 0, 0, 0)
  dataFim.setFullYear(agora.getFullYear())
  dataFim.setMonth(agora.getMonth())
  dataFim.setDate(dataFim.getDate() -1)

  const { 
      register, 
      handleSubmit,
  } = useForm<FormRelatorioRateioData>({
      defaultValues: {
        dataInicio: dataInicio.toISOString().split('T')[0],
        dataFim: dataFim.toISOString().split('T')[0]
      }
  })
  const { addBotoes } = useFormSistema()

  const handleSearch: SubmitHandler<FormRelatorioRateioData> = async (values) => {
    setPagar([])
    setReceber([])
    setCaixa([])
    setEntradas([])
    
    showLoader()

    try {
      const response = await api.get(`cerebro/relatorio-rateio?dataInicio=${values.dataInicio}&dataFim=${values.dataFim}&CodEmp=${usuario.empresa?.id}`)
      
      setPagar(response.data?.pagar)
      setReceber(response.data?.receber)
      setCaixa(response.data?.caixa)
      setEntradas(response.data?.entradas)

      hideLoader()
    } catch (error) {
      hideLoader()
    }
  }

  const handleclick = async () => {
    handleSubmit(handleSearch)()
  }

  useEffect(() => {
    addBotoes([
      {
        id: 'btnSearch',
        icone: 'search',
        cor: 'primary', 
        title: 'Buscar',
        onClick: handleclick
      },
    ], 'relatorio-rateio', true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid
      templateColumns='repeat(12, 1fr)'
      gap="1px"
      pt="5px"
      as="form"
      onSubmit={handleSubmit(handleSearch)}
    >
      <GridItem bg="white" w='100%' px="7.5px" colSpan={12} pb={2}>
        <Accordion allowToggle defaultIndex={0}>
            <AccordionItem>
                <AccordionButton 
                    bg="primary" 
                    color="white"
                    _hover={{bg:"primary", 
                    color: "white"}}
                    borderTopRadius="5px"
                >
                    <Box flex='1' textAlign='left' fontWeight="semibold">Filtros</Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                    <Grid
                        templateColumns='repeat(12, 1fr)'
                        gap="1px"
                        pt="5px"
                    >
                        <GridItem bg="white" w='100%' px="7.5px" colSpan={3} pb={2}>
                            <CampoData
                                label="Data Início"
                                placeholder="Data Início"
                                {...register('dataInicio')} />
                        </GridItem>
                        <GridItem bg="white" w='100%' px="7.5px" colSpan={3} pb={2}>
                            <CampoData
                                label="Data Fim"
                                placeholder="Data Fim"
                                {...register('dataFim')} />
                        </GridItem>
                    </Grid>
                    <Button type='submit' hidden>Buscar</Button>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
      </GridItem>
      <GridItem bg="white" w='100%' px="7.5px" colSpan={12}>
        <Tabs width="100%">
          <Grid flex="1" flexDirection="column">
            <TabList h="10" bgColor="primary" color="white">
              <Tab mt="1" fontSize="14px" fontWeight="bold" 
                _selected={{ color: 'gray.900', bg: 'white', borderTopRadius: "8px" }}
                _active={{ color: 'gray.900', bg: 'white', borderTopRadius: "8px" }}
              >
                <Text ml="1">Pagar</Text>
              </Tab>
              <Tab mt="1" fontSize="14px" fontWeight="bold" 
                _selected={{ color: 'gray.900', bg: 'white', borderTopRadius: "8px" }}
                _active={{ color: 'gray.900', bg: 'white', borderTopRadius: "8px" }}
              >
                <Text ml="1">Receber</Text>
              </Tab>
              <Tab mt="1" fontSize="14px" fontWeight="bold" 
                _selected={{ color: 'gray.900', bg: 'white', borderTopRadius: "8px" }}
                _active={{ color: 'gray.900', bg: 'white', borderTopRadius: "8px" }}
              >
                <Text ml="1">Caixa</Text>
              </Tab>
              <Tab mt="1" fontSize="14px" fontWeight="bold" 
                _selected={{ color: 'gray.900', bg: 'white', borderTopRadius: "8px" }}
                _active={{ color: 'gray.900', bg: 'white', borderTopRadius: "8px" }}
              >
                <Text ml="1">Entradas</Text>
              </Tab>
            </TabList>
          </Grid>
          <TabPanels>
            <TabPanel m="0" p="0">
              <Container flex="1" p="2" maxW="full" w="full">
                <TableContainer>
                  <Table size="sm" colorScheme='blue'>
                    <Thead bg="primary">
                      <Tr>
                        <Th color="white">Cód. Centro Custo</Th>
                        <Th color="white">Desc. Centro Custo</Th>
                        <Th color="white">Nivel Conta Fin.</Th>
                        <Th color="white">Desc. Conta Fin.</Th>
                        <Th color="white">Nivel Conta Ctb.</Th>
                        <Th color="white">Desc. Conta Ctb.</Th>
                        <Th color="white">Data Mvto</Th>
                        <Th color="white">Pessoa</Th>
                        <Th color="white">Núm. Título</Th>
                        <Th color="white">Origem</Th>
                        <Th color="white">Valor</Th>
                      </Tr>
                    </Thead>
                      <Tbody>
                        {pagar.map((i, index) => (
                          <Tr key={index}>
                            <Td>{i.CodCnc}</Td>
                            <Td>{i.CncDsc}</Td>
                            <Td>{i.CfiNiv}</Td>
                            <Td>{i.CfiDsc}</Td>
                            <Td>{i.CctNiv}</Td>
                            <Td>{i.CctDsc}</Td>
                            <Td>{i.DatMvt}</Td>
                            <Td>{i.DscMvt}</Td>
                            <Td>{i.NumItm}</Td>
                            <Td>{i.OrgItm}</Td>
                            <Td><Text align="right">{i.VlrItm}</Text></Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                </TableContainer>
              </Container>
            </TabPanel>
            <TabPanel m="0" p="0">
              <Container flex="1" p="2" maxW="full" w="full">
                <TableContainer>
                  <Table size="sm" colorScheme='blue'>
                    <Thead bg="primary">
                      <Tr>
                        <Th color="white">Cód. Centro Custo</Th>
                        <Th color="white">Desc. Centro Custo</Th>
                        <Th color="white">Nivel Conta Fin.</Th>
                        <Th color="white">Desc. Conta Fin.</Th>
                        <Th color="white">Nivel Conta Ctb.</Th>
                        <Th color="white">Desc. Conta Ctb.</Th>
                        <Th color="white">Data Vencimento</Th>
                        <Th color="white">Data Mvto</Th>
                        <Th color="white">Pessoa</Th>
                        <Th color="white">Núm. Título</Th>
                        <Th color="white">Origem</Th>
                        <Th color="white">Valor</Th>
                      </Tr>
                    </Thead>
                      <Tbody>
                        {receber.map((i, index) => (
                          <Tr key={index}>
                            <Td>{i.CodCnc}</Td>
                            <Td>{i.CncDsc}</Td>
                            <Td>{i.CfiNiv}</Td>
                            <Td>{i.CfiDsc}</Td>
                            <Td>{i.CctNiv}</Td>
                            <Td>{i.CctDsc}</Td>
                            <Td>{i.DatVen}</Td>
                            <Td>{i.DatMvt}</Td>
                            <Td>{i.DscMvt}</Td>
                            <Td>{i.NumItm}</Td>
                            <Td>{i.OrgTit}</Td>
                            <Td><Text align="right">{i.VlrItm}</Text></Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                </TableContainer>
              </Container>
            </TabPanel>
            <TabPanel m="0" p="0">
              <Container flex="1" p="2" maxW="full" w="full">
                <TableContainer>
                  <Table size="sm" colorScheme='blue'>
                    <Thead bg="primary">
                      <Tr>
                        <Th color="white">Cód. Centro Custo</Th>
                        <Th color="white">Desc. Centro Custo</Th>
                        <Th color="white">Nivel Conta Fin.</Th>
                        <Th color="white">Desc. Conta Fin.</Th>
                        <Th color="white">Nivel Conta Ctb.</Th>
                        <Th color="white">Desc. Conta Ctb.</Th>
                        <Th color="white">Data Mvto</Th>
                        <Th color="white">Descrição</Th>
                        <Th color="white">Núm. ID</Th>
                        <Th color="white">Origem</Th>
                        <Th color="white">Valor</Th>
                      </Tr>
                    </Thead>
                      <Tbody>
                        {caixa.map((i, index) => (
                          <Tr key={index}>
                            <Td>{i.CodCnc}</Td>
                            <Td>{i.CncDsc}</Td>
                            <Td>{i.CfiNiv}</Td>
                            <Td>{i.CfiDsc}</Td>
                            <Td>{i.CctNiv}</Td>
                            <Td>{i.CctDsc}</Td>
                            <Td>{i.DatMvt}</Td>
                            <Td>{i.DscMvt}</Td>
                            <Td>{i.NumItm}</Td>
                            <Td>{i.OrgItm}</Td>
                            <Td><Text align="right">{i.VlrItm}</Text></Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                </TableContainer>
              </Container>
            </TabPanel>
            
            <TabPanel m="0" p="0">
              <Container flex="1" p="2" maxW="full" w="full">
                <TableContainer>
                  <Table size="sm" colorScheme='blue'>
                    <Thead bg="primary">
                      <Tr>
                        <Th color="white">Cód. Centro Custo</Th>
                        <Th color="white">Desc. Centro Custo</Th>
                        <Th color="white">Nivel Conta Fin.</Th>
                        <Th color="white">Desc. Conta Fin.</Th>
                        <Th color="white">Nivel Conta Ctb.</Th>
                        <Th color="white">Desc. Conta Ctb.</Th>
                        <Th color="white">Data Mvto</Th>
                        <Th color="white">Data Emissão</Th>
                        <Th color="white">Pessoa</Th>
                        <Th color="white">Núm. Doc</Th>
                        <Th color="white">Origem</Th>
                        <Th color="white">Valor</Th>
                        <Th color="white">Usuário</Th>
                      </Tr>
                    </Thead>
                      <Tbody>
                        {entradas.map((i, index) => (
                          <Tr key={index}>
                            <Td>{i.CodCnc}</Td>
                            <Td>{i.CncDsc}</Td>
                            <Td>{i.CfiNiv}</Td>
                            <Td>{i.CfiDsc}</Td>
                            <Td>{i.CctNiv}</Td>
                            <Td>{i.CctDsc}</Td>
                            <Td>{i.DatMvt}</Td>
                            <Td>{i.DatEmi}</Td>
                            <Td>{i.DscMvt}</Td>
                            <Td>{i.NumItm}</Td>
                            <Td>{i.OrgItm}</Td>
                            <Td><Text align="right">{i.VlrItm}</Text></Td>
                            <Td>{i.UsuGer}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                </TableContainer>
              </Container>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </GridItem>
    </Grid>
  )
}