import { HamburgerIcon } from "@chakra-ui/icons";
import { FaArrowLeft, FaArrowRight, FaBoxOpen, FaChartBar, FaChartPie, FaClipboardList, FaConfluence, FaFile, FaFileExcel, FaFileInvoice, FaGraduationCap, FaHome, FaIdCard, FaPen, FaSave, FaSearch, FaSignOutAlt, FaSync, FaTimesCircle, FaUser } from "react-icons/fa";

interface Icone {
  [index: string]: JSX.Element;
}

export const arrayIcones = {
  'arrow-left': <FaArrowLeft />,
  'arrow-right': <FaArrowRight />,
  'box': <FaBoxOpen />,
  'chart-bar': <FaChartBar />,
  'chart-pie': <FaChartPie />,
  'clipboard-list': <FaClipboardList />,
  'confluence': <FaConfluence />,
  'file': <FaFile />,
  'file-excel': <FaFileExcel />,
  'file-invoice': <FaFileInvoice />,
  'graduation-cap': <FaGraduationCap />,
  'home': <FaHome />,
  'id-card': <FaIdCard />,
  'menu': <HamburgerIcon />,
  'pen': <FaPen />,
  'save': <FaSave />,
  'search': <FaSearch />,
  'sign-out-alt': <FaSignOutAlt />,
  'sync': <FaSync />,
  'times-circle': <FaTimesCircle />,
  'user': <FaUser />,
} as Icone

export function getIcone(chave: string, props: any) {
  const arrayIcones = {
    'arrow-left': <FaArrowLeft {...props} />,
    'arrow-right': <FaArrowRight {...props} />,
    'box': <FaBoxOpen {...props} />,
    'chart-bar': <FaChartBar {...props} />,
    'chart-pie': <FaChartPie {...props} />,
    'clipboard-list': <FaClipboardList {...props} />,
    'confluence': <FaConfluence {...props} />,
    'file': <FaFile {...props} />,
    'file-excel': <FaFileExcel {...props} />,
    'file-invoice': <FaFileInvoice {...props} />,
    'graduation-cap': <FaGraduationCap {...props} />,
    'home': <FaHome {...props} />,
    'id-card': <FaIdCard {...props} />,
    'menu': <HamburgerIcon {...props} />,
    'pen': <FaPen {...props} />,
    'save': <FaSave {...props} />,
    'search': <FaSearch {...props} />,
    'sign-out-alt': <FaSignOutAlt {...props} />,
    'sync': <FaSync {...props} />,
    'times-circle': <FaTimesCircle {...props} />,
    'user': <FaUser {...props} />,
  } as Icone

  return arrayIcones[chave]
}