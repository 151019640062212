import { FormProvider } from "../../hooks/useFormSistema";
import { useEffect } from 'react'
import { useSistema } from "../../hooks/useSistema";
import { useToast } from "@chakra-ui/react";

export function Home() {
    const { usuario, logout } = useSistema()
    const toast = useToast()

    useEffect(() => {
        const now = new Date()
        const expires = new Date(usuario.expires ?? '')

        if((!usuario.expires) || (now >= expires)){
            toast({
                title: "Seu login expirou",
                position: "top-right",
                status: "warning",
                description: 'Faça login novamente',
                isClosable: true,
                duration: 1500,
            })
            logout()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <FormProvider />
    )
}