import { forwardRef, ForwardRefRenderFunction } from "react";
import { CampoTextoGenerico, CampoTextoGenericoProps } from "./CampoTextoGenerico";

const CampoTextoBase: ForwardRefRenderFunction<HTMLInputElement, CampoTextoGenericoProps> = 
    ({ ...others }, ref) => {
    return (
        <CampoTextoGenerico type="text" ref={ref} {...others} />
    )
}

export const CampoTexto = forwardRef(CampoTextoBase)