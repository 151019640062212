import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Grid, GridItem, Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react"
import { useState, useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form";
import { CampoData } from "../../../components/campos/CampoData";
import { CampoSelect, CampoSelectOption } from "../../../components/campos/CampoSelect";
import { useFormSistema } from "../../../hooks/useFormSistema"
import { useSistema } from "../../../hooks/useSistema"
import { api } from "../../../services/api"

export interface RelItem {
    id?: number;
    RazNom?: string;
    CncDsc?: string;
    CntCtb?: string;
    CntFin?: string;
    DatEmi?: string;
    DatVen?: string;
    VlrBrt?: string;
    VlrAcs?: string;
    VlrDsc?: string;
    VlrLiq?: string;
    VlrRec?: string;
    SldTit?: string;
    OriTit?: string;
}

export interface RelTotal {
    VlrBrt?: string;
    VlrAcs?: string;
    VlrDsc?: string;
    VlrLiq?: string;
    VlrRec?: string;
    SldTit?: string;
}

interface FormMatriculasPorAlunoData {
  dataInicio?: string;
  dataFim?: string;
  ordem?: string;
  situacao?: string;
}

const opcoesOrdem: CampoSelectOption[] = [
    {
      valor: 'pessoa', 
      texto: 'Pessoa'
    },
    {
        valor: 'vencimento', 
        texto: 'Vencimento'
    },
]

const opcoesSit: CampoSelectOption[] = [
    {
      valor: 'todos', 
      texto: 'Todos'
    },
    {
        valor: 'aberto', 
        texto: 'Apenas Abertos'
    },
]

export function FormRelatorioAnaliticoContasReceber(){
    const [itens, setItens] = useState<RelItem[]>([])
    const [totais, setTotais] = useState<RelTotal>({})
    const [link, setLink] = useState<string>("")

    const { showLoader, hideLoader, usuario } = useSistema()
    const { addBotoes } = useFormSistema()

    //Define datas
    let agora = new Date()
    let dataInicio = new Date(agora.getFullYear(), agora.getMonth() - 1, 1, 0, 0, 0)
    let dataFim = new Date(agora.getFullYear(), agora.getMonth(), 1, 0, 0, 0)
    dataFim.setDate(dataFim.getDate() -1)

    const { 
        register, 
        handleSubmit,
    } = useForm<FormMatriculasPorAlunoData>({
        defaultValues: {
            ordem: 'pessoa',
            situacao: 'todos',
            dataInicio: dataInicio.toISOString().split('T')[0],
            dataFim: dataFim.toISOString().split('T')[0]
        }
    })
    
    const handleSearch: SubmitHandler<FormMatriculasPorAlunoData> = async (values) => {
        try {
            showLoader()
            setItens([])

            const url = `galileu/matriculas/relatorio-analitico-contas-receber?dataInicio=${values.dataInicio}&dataFim=${values.dataFim}&CodEmp=${usuario.empresa?.id}&ordem=${values.ordem}&situacao=${values.situacao}`
            setLink(url)

            const response = await api.get(url)
            
            setItens(response.data?.itens)
            setTotais(response.data?.totais)

            hideLoader()
        } catch (error) {
            hideLoader()
        }
    }

    const handleExcel = async () => {
        try {
            showLoader()

            const response = await api.get(link + '&excel=1')
            if(response.data?.url){
                const link = document.createElement('a');
                link.href = response.data?.url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

            hideLoader()
        } catch (error) {
            hideLoader()
        }
    }
    
    
    const handleclick = async () => {
      handleSubmit(handleSearch)()
    }
    

    useEffect(() => {
        addBotoes([
            {
                id: 'btnExcel',
                icone: 'file-excel',
                cor: 'green', 
                title: 'Download Excel',
                onClick: handleExcel,
                onClickProps: link,
            },
            {
                id: 'btnSearch',
                icone: 'search',
                cor: 'primary', 
                title: 'Buscar',
                onClick: handleclick,
            },
        ], 'relatorio-analitico-contas-receber', true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [link])
    

    useEffect(() => {
        handleclick()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [link])
    
    return (
        <Grid
            templateColumns='repeat(12, 1fr)'
            gap="1px"
            pt="5px"
            as="form"
            onSubmit={handleSubmit(handleSearch)}
        >
            <GridItem bg="white" w='100%' px="7.5px" colSpan={12} pb={2}>
                <Accordion allowToggle defaultIndex={0}>
                    <AccordionItem>
                        <AccordionButton 
                            bg="primary" 
                            color="white"
                            _hover={{bg:"primary", 
                            color: "white"}}
                            borderTopRadius="5px"
                        >
                            <Box flex='1' textAlign='left' fontWeight="semibold">Filtros</Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel>
                            <Grid
                                templateColumns='repeat(12, 1fr)'
                                gap="1px"
                                pt="5px"
                            >
                                <GridItem bg="white" w='100%' px="7.5px" colSpan={3} pb={2}>
                                    <CampoData
                                        label="Data Início"
                                        placeholder="Data Início"
                                        {...register('dataInicio')} />
                                </GridItem>
                                <GridItem bg="white" w='100%' px="7.5px" colSpan={3} pb={2}>
                                    <CampoData
                                        label="Data Fim"
                                        placeholder="Data Fim"
                                        {...register('dataFim')} />
                                </GridItem>
                                <GridItem bg="white" w='100%' px="7.5px" colSpan={2} pb={2}>
                                    <CampoSelect
                                        label="Ordem"
                                        dados={opcoesOrdem}
                                        {...register('ordem')} />
                                </GridItem>
                                <GridItem bg="white" w='100%' px="7.5px" colSpan={2} pb={2}>
                                    <CampoSelect
                                        label="Situação"
                                        dados={opcoesSit}
                                        {...register('situacao')} />
                                </GridItem>
                            </Grid>
                            <Button type='submit' hidden>Buscar</Button>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </GridItem>
            <GridItem bg="white" w='100%' px="7.5px" colSpan={12}>
                <TableContainer>
                    <Table size="sm" colorScheme='blue'>
                        <Thead bg="primary">
                            <Tr>
                                <Th color="white">Pessoa</Th>
                                <Th color="white">Centro de Receita</Th>
                                <Th color="white">Conta Contábil</Th>
                                <Th color="white">Conta Financeira</Th>
                                <Th color="white">Data Emissão</Th>
                                <Th color="white">Data Venc.</Th>
                                <Th color="white">Valor Bruto</Th>
                                <Th color="white">Acréscimo</Th>
                                <Th color="white">Desconto</Th>
                                <Th color="white">Valor Líquido</Th>
                                <Th color="white">Valor Recebido</Th>
                                <Th color="white">Em Aberto</Th>
                                <Th color="white">Origem</Th>
                            </Tr>
                        </Thead>
                            <Tbody>
                                {itens.map((a) => {
                                    return (
                                <Tr key={a.id} _hover={{ backgroundColor: "gray.100" }}>
                                    <Td>{a.RazNom}</Td>
                                    <Td>{a.CncDsc}</Td>
                                    <Td>{a.CntCtb}</Td>
                                    <Td>{a.CntFin}</Td>
                                    <Td>{a.DatEmi}</Td>
                                    <Td>{a.DatVen}</Td>
                                    <Td textAlign="end">{a.VlrBrt}</Td>
                                    <Td textAlign="end">{a.VlrAcs}</Td>
                                    <Td textAlign="end">{a.VlrDsc}</Td>
                                    <Td textAlign="end">{a.VlrLiq}</Td>
                                    <Td textAlign="end">{a.VlrRec}</Td>
                                    <Td textAlign="end">{a.SldTit}</Td>
                                    <Td>{a.OriTit}</Td>
                                </Tr>)
                                })}
                            </Tbody>
                        {((itens.length > 0) && 
                        <Tfoot bg="primary">
                            <Tr>
                                <Th color="white"></Th>
                                <Th color="white"></Th>
                                <Th color="white"></Th>
                                <Th color="white"></Th>
                                <Th color="white"></Th>
                                <Th color="white"></Th>
                                <Th color="white" textAlign="end">{totais.VlrBrt}</Th>
                                <Th color="white" textAlign="end">{totais.VlrAcs}</Th>
                                <Th color="white" textAlign="end">{totais.VlrDsc}</Th>
                                <Th color="white" textAlign="end">{totais.VlrLiq}</Th>
                                <Th color="white" textAlign="end">{totais.VlrRec}</Th>
                                <Th color="white" textAlign="end">{totais.SldTit}</Th>
                                <Th color="white"></Th>
                            </Tr>
                        </Tfoot>
                        )}
                    </Table>
                </TableContainer>
            </GridItem>
        </Grid>
    )
}