import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
    colors: {
        primary: '#007BFF',
        menuBackground: '#343A40',
        menuBackgroundSelected: '#494E53',
        subMenuBackground: '#3E444A',
        menuFont: '#C2C7D0'
    },
    styles: {
        global: {
            body: {
                bg: 'gray.100',
                color: 'gray.900'
            }
        }
    }
})