import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Checkbox, CheckboxGroup, Grid, GridItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure, useMediaQuery, useToast } from "@chakra-ui/react"
import { useState, useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form";
import { CampoSelect, CampoSelectOption } from "../../components/campos/CampoSelect";
import { CampoTexto } from "../../components/campos/CampoTexto";
import { useFormSistema } from "../../hooks/useFormSistema"
import { useSistema } from "../../hooks/useSistema"
import { api } from "../../services/api";

export interface MatriculaAluno {
    id?: number;
    AnoCrs?: string;
    DatMat?: string;
    AnoMat ?: string;
    RazNom?: string;
    GraPar?: string;
    CPF?: string;
    RG?: string;
    LgdPrc?: string;
    NumPrc?: string;
    CmpPrc?: string;
    BaiPrc?: string;
    Tele?: string;
    email?: string;
    NomAlu?: string;
    DatNsc?: string;
    DocRg?: string;
    EmlAlu?: string;
    TelAlu?: string;
    LdgAlu?: string;
    NumAlu?: string;
    CplAlu?: string;
    BaiAlu?: string;
    NomMae?: string;
    EmlMae?: string;
    TelMae?: string;
    NomPai?: string;
    EmlPai?: string;
    TelPai?: string;
    CidAlu?: string;
    UfAl?: string;
    CidCdp?: string;
    UnfPr?: string;
    AnoCrsFmt?: string;
    TrnAlu?: string;
}

interface FormMatriculasPorAlunoData {
    aluno?: string;
    responsavel?: string;
    ordem?: string;
    id?: string[]
}

const opcoesOrdem: CampoSelectOption[] = [
    {
        valor: 'aluno', 
        texto: 'Aluno'
    },
    {
        valor: 'data', 
        texto: 'Data'
    },
    {
        valor: 'responsavel', 
        texto: 'Responsável'
    },
]

export function FormMatriculasPorAluno(){
    const [itens, setItens] = useState<MatriculaAluno[]>([])
    const [selected, setSelected] = useState<(string|number)[]>([])
    const todos = (selected.length === itens.length)
    const alguns = ((selected.length > 0) && (selected.length < itens.length))
    const width = (useMediaQuery('(min-width: 900px)')[0] ? 818 : 500)

    const { showLoader, hideLoader } = useSistema()
    const { addBotoes } = useFormSistema()
    const toast = useToast()
    const disclosurePDF = useDisclosure()

    const { 
        register, 
        handleSubmit, 
        setValue
    } = useForm<FormMatriculasPorAlunoData>({
        defaultValues: {
            ordem: 'aluno'
        }
    })

    const handlePDF: SubmitHandler<FormMatriculasPorAlunoData> = async (values) => {
        if(!values.id || values.id?.length === 0){
            toast({
                title: "Aviso",
                position: "top-right",
                status: "warning",
                description: 'Nenhum contrato selecionado',
                isClosable: true,
                duration: 1500,
            })

            return
        }
        
        showLoader()

        setTimeout(() => {
            hideLoader()
            disclosurePDF.onOpen()
        }, 1000);
    }
    
    const handleSearch: SubmitHandler<FormMatriculasPorAlunoData> = async (values) => {
        try {
            showLoader()
            setItens([])

            const response = await api.get(`galileu/matriculas/por-aluno?NomAlu=${values.aluno}&RazNom=${values.responsavel}&ordem=${values.ordem}`)
            
            setItens(response.data)
            setSelected([])

            hideLoader()
        } catch (error) {
            hideLoader()
        }
    }
    
    const handleclick = async (acao: string) => {
        if(acao === 'search'){
            handleSubmit(handleSearch)()
        } else {
            handleSubmit(handlePDF)()
        }
    }
    

    useEffect(() => {
            addBotoes([
                {
                    id: 'btnSearch',
                    icone: 'search',
                    cor: 'primary', 
                    title: 'Buscar',
                    onClick: handleclick,
                    onClickProps: 'search',
                },
                {
                    id: 'btnContrato',
                    icone: 'file-invoice',
                    cor: 'green.400', 
                    title: 'Gerar Contratos',
                    onClick: handleclick,
                    onClickProps: 'pdf'
                }
            ], 'matriculas-por-aluno', true)

            handleSearch({
                aluno: '', responsavel: '', ordem: 'aluno'
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // console.log('contratos')
    // console.log(contratos)
    // console.log('ids')
    // console.log(ids)
    
    return (
        <Grid
            templateColumns='repeat(12, 1fr)'
            gap="1px"
            pt="5px"
            as="form"
            onSubmit={handleSubmit(handleSearch)}
        >
            <Modal isOpen={disclosurePDF.isOpen} onClose={disclosurePDF.onClose} scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent minW={width} maxW={width} h="80vh">
                    <ModalHeader 
                        bg="primary" color="white" 
                        fontWeight="semibold" p="2"
                    >Geração de Contratos</ModalHeader>
                    <ModalCloseButton color="white" fontWeight="semibold" />
                    <ModalBody>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <GridItem bg="white" w='100%' px="7.5px" colSpan={12} pb={2}>
                <Accordion allowToggle defaultIndex={0}>
                    <AccordionItem>
                        <AccordionButton 
                            bg="primary" 
                            color="white"
                            _hover={{bg:"primary", 
                            color: "white"}}
                            borderTopRadius="5px"
                        >
                            <Box flex='1' textAlign='left' fontWeight="semibold">Filtros</Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel>
                            <Grid
                                templateColumns='repeat(12, 1fr)'
                                gap="1px"
                                pt="5px"
                            >
                                <GridItem bg="white" w='100%' px="7.5px" colSpan={5} pb={2}>
                                    <CampoTexto
                                        label="Aluno"
                                        placeholder="Aluno"
                                        {...register('aluno')} />
                                </GridItem>
                                <GridItem bg="white" w='100%' px="7.5px" colSpan={5} pb={2}>
                                    <CampoTexto
                                        label="Responsável"
                                        placeholder="Responsável"
                                        {...register('responsavel')} />
                                </GridItem>
                                <GridItem bg="white" w='100%' px="7.5px" colSpan={2} pb={2}>
                                    <CampoSelect
                                        label="Ordem"
                                        dados={opcoesOrdem}
                                        {...register('ordem')} />
                                </GridItem>
                            </Grid>
                            <Button type='submit' hidden>Buscar</Button>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </GridItem>
            <GridItem bg="white" w='100%' px="7.5px" colSpan={12}>
                <TableContainer>
                    <Table size="sm" colorScheme='blue'>
                        <Thead bg="primary">
                            <Tr>
                                <Th>
                                    <Checkbox 
                                        isChecked={todos}
                                        isIndeterminate={alguns}
                                        onChange={(e) => { 
                                            if(!todos){
                                                setSelected(itens.map((a) => a.id + ''))
                                                setValue('id', itens.map((a) => a.id + ''))
                                            } else {
                                                setSelected([])
                                                setValue('id', [])
                                            }
                                        }}
                                    />
                                </Th>
                                <Th color="white">Data Matrícula</Th>
                                <Th color="white">Aluno(a)</Th>
                                <Th color="white">Responsável</Th>
                                <Th color="white">Ano Curso</Th>
                            </Tr>
                        </Thead>
                        <CheckboxGroup 
                            colorScheme='blue' 
                            defaultValue={itens.map((a) => (a.id + ''))} 
                            value={selected}
                            onChange={(e) => { 
                                setSelected(e)
                            }}>
                            <Tbody>
                                {itens.map((a) => {
                                    return (
                                <Tr key={a.id} _hover={{ backgroundColor: "gray.100" }}
                                // onContextMenu={(e) => {
                                //     console.log(e)
                                //     console.log('context')
                                //     e.preventDefault()
                                // }}
                                >
                                    <Td><Checkbox value={a.id + ''} { ...register('id') } /></Td>
                                    <Td>{a.DatMat}</Td>
                                    <Td>{a.NomAlu}</Td>
                                    <Td>{a.RazNom}</Td>
                                    <Td>{a.AnoCrsFmt}</Td>
                                </Tr>)
                                })}
                            </Tbody>
                        </CheckboxGroup>
                    </Table>
                </TableContainer>
            </GridItem>
        </Grid>
    )
}