import { HamburgerIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import { useSistema } from "../../hooks/useSistema";

export function BotaoSidebar() {
  const { sidebar } = useSistema()

  return (
      <Box as="button" borderBottom="2px solid" borderColor="transparent" 
          fontSize="md" marginBottom="-2px" px="4" py="2" 
          transitionDuration="normal" transitionProperty="common"
          _active={{ bg: 'gray.200' }}
          _disabled={{ cursor: 'not-allowed', opacity: 0.4 }}
          _selected={{ borderColor: "currentColor", color: "blue.600" }}
          onClick={(sidebar.isOpen ? sidebar.onClose : sidebar.onOpen)}
      >
          <HamburgerIcon />
      </Box>
  );
}