import { Center, Container, Flex, Grid, Heading, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { createContext, useContext, useState } from 'react'
import { BotaoSidebar } from '../components/botoes/BotaoSidebar';
import { BotaoTitulo, BotaoTituloProps } from '../components/botoes/BotaoTitulo';
import { FormTab } from '../components/layout/FormTab';
import { arrayIcones } from '../components/layout/icones';
import { ModalTrocarEmpresa } from '../components/layout/ModalTrocarEmpresa';
import { Sidebar } from '../components/layout/Sidebar';
import { TagUser } from '../components/layout/TagUser';
import { Dashboard } from '../modules/dashboard/Dashboard';
import { FormMatriculasPorAluno } from '../modules/matriculas/FormMatriculasPorAluno';
import { FormRelatorioAnaliticoMensalidades } from '../modules/matriculas/relatorio-analitico/FormRelatorioAnaliticoMensalidades';
import { FormRelatorioRateio } from '../modules/rateio/FormRelatorioRateio';
import { useSistema } from './useSistema';
import { FormRelatorioAnaliticoContasReceber } from '../modules/matriculas/relatorio-analitico/FormRelatorioAnaliticoContasReceber';
import { FormRelatorioAnaliticoContasPagar } from '../modules/matriculas/relatorio-analitico/FormRelatorioAnaliticoContasPagar';

export interface FormProps {
    chave: string,
    icone: string,
    nome: string,
    titulo: string,
    botoes: BotaoTituloProps[],
    fechar: boolean,
}

interface FormContextData {
    forms: FormProps[],
    addForm: (form: FormProps) => void,
    removeForm: (chave: string) => void,
    resetForms: () => void,
    addBotoes: (botoes: BotaoTituloProps[], chaveForm: string, substituir: boolean) => void,
}

const formHome: FormProps = {
    chave: 'home',
    icone: 'home',
    nome: 'Home',
    titulo: 'Support Contabilidade',
    fechar: false,
    botoes: [],
}

const FormContext = createContext<FormContextData>(
    {} as FormContextData
)

function getForm(chave: string){
    switch (chave) {
        case 'home':
            return <Dashboard />
        case 'matriculas-por-aluno':
            return <FormMatriculasPorAluno />
        case 'relatorio-analitico-mensalidades':
            return <FormRelatorioAnaliticoMensalidades />
        case 'relatorio-analitico-contas-receber':
            return <FormRelatorioAnaliticoContasReceber />
        case 'relatorio-analitico-contas-pagar':
            return <FormRelatorioAnaliticoContasPagar />
        case 'relatorio-rateio':
            return <FormRelatorioRateio />
        default:
            return null;
    }
}

export function FormProvider() {
    const [forms, setForms] = useState<FormProps[]>([formHome])
    const [tabIndex, setTabIndex] = useState(0)
    const { modalEmpresa } = useSistema()

    function addForm(form: FormProps) {
        let indice = forms.findIndex((f) => f.chave === form.chave)
        
        if(indice <= 0){
            setForms([...forms, form])
            indice = forms.length
        }
        setTabIndex(indice)
    }

    function removeForm(chave: string) {
        const lista = forms.filter((f) => f.chave !== chave)

        setTabIndex(tabIndex-1)
        setForms(lista)
    }

    function resetForms() {
        setTabIndex(0)
        setForms([formHome])
    }

    function addBotoes(botoes: BotaoTituloProps[], chaveForm: string, substituir: boolean){
        const lista = forms.map((f) => {
            if(f.chave !== chaveForm)
                return f
            else {
                if(substituir){
                    f.botoes = botoes
                } else {
                    f.botoes = [ ...f.botoes, ...botoes ]
                }

                return f
            }
        })
        setForms(lista) 
    }

    const handleTabsChange = (index: number) => {
      setTabIndex(index)
    }

    return (
        <FormContext.Provider value={{ forms, addForm, removeForm, resetForms, addBotoes }}>
            <Flex h="100vh" w="100vw">
                <Sidebar />
                <Flex width="100%">
                    <Tabs width="100%" index={tabIndex} onChange={handleTabsChange}>
                        <Grid flex="1" flexDirection="column">
                            <TabList h="10" bgColor="primary" color="white">
                                <BotaoSidebar />
                                {forms.map((f) => {
                                    return <FormTab key={f.chave} chave={f.chave} icon={arrayIcones[f.icone]} text={f.nome} removeForm={removeForm} btnClose={f.fechar} />
                                })}
                                <TagUser />
                            </TabList>
                        </Grid>
                        <TabPanels>
                            {forms.map((f) => {
                                return (
                                    <TabPanel m="0" p="0" key={f.chave}>
                                        <Container
                                            flex="1"
                                            px="2"
                                            maxW="full" w="full"
                                        >
                                            <Heading
                                                fontSize="2xl"
                                                fontWeight="semibold"
                                                py="10px"
                                                mb="2"
                                            >
                                                <Flex flex="1" justifyContent="space-between" flexDir="row">
                                                    <Text>
                                                        {f.titulo}
                                                    </Text>
                                                    <Center>
                                                        {f.botoes.map(
                                                            (b) => <BotaoTitulo key={b.id} {...b} />
                                                        )}
                                                        {f.fechar && <BotaoTitulo 
                                                            id="fechar" icone="times-circle" 
                                                            cor="red" title="Fechar" 
                                                            onClick={removeForm}
                                                            onClickProps={f.chave}
                                                        />}
                                                    </Center>
                                                </Flex>
                                            </Heading>
                                            <Container 
                                                overflowY="auto"
                                                maxW="full" w="full"
                                                maxH="calc(100vh - 97px)" h="calc(100vh - 97px)"
                                            >
                                                {getForm(f.chave)}
                                            </Container>
                                        </Container>
                                    </TabPanel>
                                )
                            })}
                        </TabPanels>
                    </Tabs>
                </Flex>
            </Flex>
            <ModalTrocarEmpresa disclosure={modalEmpresa} />
        </FormContext.Provider>
    )
}

export function useFormSistema() {
    const context = useContext(FormContext)

    return context;
}