import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from "@chakra-ui/react"
import { theme } from './styles/theme';
import { App } from './App';
import { SistemaProvider } from './hooks/useSistema';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <ChakraProvider theme={theme}>
      <SistemaProvider>
        <App />
      </SistemaProvider>
    </ChakraProvider>
);
